<template>
    <div> 
        <v-toolbar v-if="show_toolbar"
            @click="ON_SEARCH(selling_model)" roundedd="" dark flat height="60"
            :color="LocalCashup?'primary':'grey darken-1'" 
            class="ma-">
            <v-autocomplete  class="mr-2"
                v-model="search_item" 
                :disabled="inputLoading || !LocalCashup|| !selling_model"
                :items="ItemsData" dense
                append-icon="search"
                outlined :rounded="false"
                :label="selling_model?'Search for Item':'You Must Select Selling Model First'"
                color="blue-grey lighten-2" 
                item-text="name"
                item-value="key" 
                :filter="(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                            if(!a||!b){return false}
                            a = ''+a; b = ''+b
                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                        } 
                        let first = index(itemText,queryText)
                        let second = item?index(item.code,queryText):false
                        let third = item?index(item.brand_name,queryText):false
                        let forth = item?index(item.category_name,queryText):false
                        if(!item){return first}  
                        return first || second || third || forth 
                }"
                >
                <template v-slot:selection="data">
                    {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                    <template v-if="(typeof data.item !=='object')" >
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                    <v-list-item-avatar tile color="grey">
                        <img :src="data.item.image" v-if="data.item.image">
                        <v-icon v-else>local_offer</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="selling_model=='Retail Price'">
                        {{ MBS.actions.money(data.item.retail_price) }}
                    </v-list-item-action>
                    <v-list-item-action v-else-if="selling_model=='Wholesale Price'">
                        {{ MBS.actions.money(data.item.wholesale_price) }}
                    </v-list-item-action>
                    </template>
                </template>
            </v-autocomplete>  

            <!-- //IF CARD -->
            <v-menu   v-if="SELECTED_CARD"
                v-model="menu_selected_card"
                :close-on-content-click="true" 
                offset-y  offset-overflow
                :nudge-width="90" rounded="xl"
                >
                <template v-slot:activator="{ on, attrs }" >   
                    <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                        <v-icon>mdi-credit-card-scan </v-icon>
                        <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                    </v-btn>  
                </template> 

                <v-card  v-if="ud"  
                    color="lighten-1" class="">  
                    <v-card-title class="secondary white--text py-" dark>
                        Selected Card
                    </v-card-title> 
                    <v-list dense> 
                        <div v-if="SELECTED_CARD">
                            <v-list-item>Card Holder: {{SELECTED_CARD.customer_name}}</v-list-item> 
                            <v-list-item>Card Number: {{SELECTED_CARD.code}}</v-list-item> 
                            <v-list-item>Expire Date: {{SELECTED_CARD.card_expire_date}}</v-list-item> 
                            <v-list-item>Balance: MWK{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item> 
                        </div>
                        <v-divider /> 
                        <v-list-item :class="'secondary--text text-center'" @click="DESELECT_CARD">
                            <v-layout justify-center>
                                Clear
                            </v-layout>
                        </v-list-item> 
                    </v-list>  
                </v-card>  
            </v-menu>  
            
            <!-- //IF ORDER -->
            <v-menu   v-if="ITEM_ORDER"
                v-model="menu_item_order"
                :close-on-content-click="true" 
                offset-y  offset-overflow
                :nudge-width="90" rounded="xl"
                >
                <template v-slot:activator="{ on, attrs }" >   
                    <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                        <v-icon>mdi-cart</v-icon>
                        <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                    </v-btn>  
                </template> 

                <v-card  v-if="ud"  
                    color="lighten-1" class="">  
                    <v-card-title class="secondary white--text py-" dark>
                        Selected Order
                    </v-card-title> 
                    <v-list dense> 
                        <!-- <v-list-item @click="EDIT_ITEM_ORDER(ITEM_ORDER)">
                            <v-list-item-action><v-icon>edit</v-icon></v-list-item-action> 
                            <v-list-item-content>Update Order</v-list-item-content> 
                        </v-list-item>   -->
                        <v-list-item @click="PRINT_ITEM_ORDER(ITEM_ORDER)">
                            <v-list-item-action><v-icon>print</v-icon></v-list-item-action> 
                            <v-list-item-content>Print Order</v-list-item-content> 
                        </v-list-item> 
                        <!-- <v-list-item @click="PAY_ITEM_ORDER(ITEM_ORDER)">
                            <v-list-item-action><v-icon>mdi-cash</v-icon></v-list-item-action> 
                            <v-list-item-content>Pay Order</v-list-item-content> 
                        </v-list-item>   -->
                        <v-list-item @click="CONFIRM_DELETE_ITEM_ORDER(ITEM_ORDER)">
                            <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action> 
                            <v-list-item-content>Cancel Order</v-list-item-content> 
                        </v-list-item> 
                        <v-divider/> 
                        <v-list-item :class="'secondary--text text-center'" @click="CLEAR_ALL_DATA()">
                            <v-layout justify-center>
                                Clear
                            </v-layout>
                        </v-list-item> 
                    </v-list>  
                </v-card>  
            </v-menu>  
            
            <v-menu  
                v-model="menu_selling_mode"
                :close-on-content-click="true" 
                offset-y  offset-overflow
                :nudge-width="90" rounded="xl"
                >
                <template v-slot:activator="{ on, attrs }">  
                    <v-btn  v-on="on" class=" mb-1"
                        ref="btn_selling_mode"
                        :disabled="CompanySettings?CompanySettings.only_retail_model||CompanySettings.only_wholesale_model:false"  
                        color="secondary">
                        {{selling_model?selling_model:'Select'}}
                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                    </v-btn> 
                </template> 

                <v-card  v-if="ud"  
                    color="lighten-1" class="">  
                    <v-card-title class="secondary white--text py-" dark>
                        Select Selling Mode 
                    </v-card-title> 
                    <v-list dense> 
                        <div v-for="(model,index) in sellingModels" :key="index">
                            <v-list-item :class="model == selling_model?'secondary--text':''" @click="selling_model=model">{{model}}</v-list-item> 
                        </div>
                    </v-list>  
                </v-card>  
            </v-menu>  
        </v-toolbar> 
         
        <v-card color=" " v-if="show_table"
            tile="" :height="height"
            flat=""  
            >  
            <!-- //NO SELECTED ITEMS -->
            <v-layout v-if="!MBS.actions.SIZE(SearchedItems)" fill-height align-center justify-center>
                <v-chip large color="b1" >
                    <v-icon class="mx-5" color="grey">mdi-tag</v-icon>
                    <span class="grey--text  mr-5">
                        No Item 
                    </span>
                </v-chip>
            </v-layout>
    
            <!-- //TABLE VIEW -->
            <div v-else-if="true" class=" "> 
                <v-simple-table  class="   " dense :height="height" fixed-header >
                    <!-- <template v-slot:default>
                    </template> -->
                    <thead class="primary">  
                        <tr class="font-weight-bold primary "> 
                            <th class="ma-0 pa-0 primary" style="width:'5px'"> 
                                <v-switch :inset="false" dark   style="height: ; margin: 5px;"
                                    v-model="item_multiple_select"
                                    label=""   hide-details
                                    class="ml-1"
                                ></v-switch> 
                            </th>
                            <th class=" text-left b primary  white--text">
                                ITEM NAME
                            </th> 
                            <th class="text-center1  b  primary white--text">
                                RETAIL PRICE  
                            </th>
                            <th class="text-center1  primary white--text">
                                WHOLESALE PRICE
                            </th>
                            <th class="text-center1  b  primary white--text"> 
                                STOCK
                            </th>  
                        </tr> 
                        <!-- <v-divider /> -->
                    </thead>
                    <tbody>
                        <tr class="pointer "  
                            v-for="(item,index) in SearchedItems"
                            :key="index" 
                            :class="item.checked?'checked':''"
                            @click="ON_SELECT_ITEM(item,index)"
                            @mousedown="startLongClick"
                            @mouseup="endLongClick"
                            >
                            <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                    v-if="item_multiple_select" icon 
                                    color="primary" 
                                    v-model="item.checked" 
                                    />  
                                <!-- <v-btn @click.stop="CHECK_ITEM(item,index)" v-if="item_multiple_select" icon class="">
                                    <v-icon v-if="!item.checked">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon v-else>mdi-checkbox-marked</v-icon>
                                </v-btn>  -->
                                <span v-else icon >
                                    <div >{{ index+1 }}</div>
                                    <!-- <v-icon v-if="item.expand">expand_more</v-icon>
                                    <v-icon v-else>chevron_right</v-icon> -->
                                </span>  
                            </td>
                            <td class=" with-divider ">
                                {{ item.name }}
                            </td> 
                            <td class=" with-divider text-right ">
                                {{ MBS.actions.money(item.retail_price) }}
                            </td>  
                            <td class="with-divider text-right ">
                                {{ MBS.actions.money(item.wholesale_price) }}
                            </td> 
                            <td class="with-divider text-right">
                                {{ toNumber(outlet_code?item[outlet_code+'_number_quantity']:item.number_quantity) }}
                            </td>   
                        </tr>
                        <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="isLoading" infinite-scroll-distance="200">
                            <v-progress-circular v-if="isLoading" indeterminate></v-progress-circular>
                        </div>
                        <v-divider />
                    </tbody>
                </v-simple-table> 
            </div> 
        </v-card>

        <!-- //DIALOGS -->
        <div>
            <!-- EDIT ITEM DIALOG -->
            <v-dialog
                v-model="dialog_edit_selected_item"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="800px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b1" v-if="current_selected_item">
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-tag</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ current_selected_item.name }}</v-list-item-title> 
                                <v-list-item-subtitle>{{ current_selected_item.category_name }}</v-list-item-subtitle>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-layout align-center >
                                    <div class="mr-1 not-f3 mt-2">MWK</div>
                                    
                                    <span  class="not-f8 font-weight-bold"> 
                                        {{MBS.actions.money(CALCULATE_PRICE(current_selected_item))}} 
                                    </span>
                                </v-layout>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                        <form @submit.prevent="UPDATE_SELECTED_ITEM()">
                            <v-card-text class="b1">
                                <v-row>
                                    <v-col cols="12" sm="6" class="py-0"> 
                                        <div>{{current_selected_item.selling_model}}</div> 
                                        <v-text-field dense color="primary"   
                                            :disabled="CompanySettings?CompanySettings.edit_selling_price?false:true:true"
                                            filled outlined v-mask="currencyMask"
                                            v-model="current_selected_item.sold_price"  
                                            outline
                                        ></v-text-field>  
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0 " >
                                        <div>Quantity (QTY)</div>
                                            <v-text-field dense autofocus
                                                filled outlined 
                                                v-model="current_selected_item.quantity"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_quantity',ref:'ref_current_selected_item_quantity'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                    
                                    <v-col cols="12" sm="12" class="py-0" v-if="CompanySettings?CompanySettings.allow_selling_discount:false" >
                                        <div>Item Discount</div>
                                            <v-text-field dense  
                                                :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="current_selected_item.discount"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                    <!-- <v-col   cols="12" sm="6" class="py-0 " v-if="CompanySettings?CompanySettings.allow_selling_discount:false">
                                        <div>Discount Percent (%)</div>
                                            <v-text-field dense 
                                            :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="input_discount_percentage"
                                                outline
                                            ></v-text-field> 
                                    </v-col> -->
                                     
                                </v-row> 
                            </v-card-text> 

                             
                            <v-layout row wrap class="ma-0  mt-4">
                                <v-flex sm12 md6> 
                                    <v-card height="100%" hover class="mr-2 "> 
                                        <v-layout justify-center align-center fill-height class="mbs-border primary lighten-5  pa-2" :style="'--size:1px; --color:grey; --conner:3px'"> 
                                            <mbs-keyboard 
                                                :type="'number'"
                                                :row="'5'"
                                                :key_function="true"
                                                />
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-card height="100%" hover>
                                        <v-layout column  class="ma-0" fill-height justify-center align-center>  
                                            <v-spacer></v-spacer>
                                            <v-btn @click="CONFIRM_REMOVE_ITEM(current_selected_item)"
                                                class="my-2 font-weight-bold" 
                                                color="primary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    <div class="not-f5 mt-1">Remove Item</div> 
                                                </v-layout>
                                            </v-btn>  
                                            <v-btn type="submit"
                                                class="my-2 font-weight-bold"   
                                                color="secondary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">mdi-upload</v-icon>
                                                    <div class="not-f5 mt-1">Update Item</div> 
                                                </v-layout>
                                            </v-btn>   
                                        </v-layout> 
                                    </v-card>
                                </v-flex> 
                            </v-layout>   
                        </form>
                    </div> 
                </v-card> 
            </v-dialog>  
        </div>
    </div>
</template> 
<script> 

    import InfiniteScroll from 'vue-infinite-scroll';
    import DATA from "../../plugins/DATA";
    import {mapState} from "vuex" 
    const PAGE_NAME = "SELECT_ITEMS"

    export default {
        props:['show_toolbar','show_table','height','search_key',
        'items','color','textColor','opacity','title','text','outlet_code',
        'selected_card','item_order'],
        directives: {
            InfiniteScroll,
        },
        data(){
            return{
                show:false,   
                item_multiple_select:false,   
                dialog_edit_selected_item:false,   
                menu_selling_mode:false, 
                menu_selected_card:false, 
                menu_select_order_option:false, 
                menu_item_order:false, 
                current_selected_item:null,   
                input:{},   
                focused_field:null,
                input_discount_amount:null,   
                input_discount_percentage:null,   
                inputLoading:false,
                longClickTimer: null,
                longClickDetected: false,

                
                selectedRows: [],
                lastSelectedRow: -1,
                editingCell: null,

                pagination: null,
                paginatedItems: [],
                isLoading: false,
                currentPage: 1,
                totalPages: 1,
                pageSize: 20,

                sellingModels:["Retail Price","Wholesale Price"], 
                selling_model:null,
                search_item:'', 
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            this.loadMore();
            this.INITIALIZE_SELLING_MODE()
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 
            }), 

            ItemsData(){
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                return JoinedItems?JoinedItems:Items
            },
            UseableItems(){
                let items = this.items
                let ItemsData = this.ItemsData
                return ItemsData?ItemsData:items

            }, 
            SearchedItems(){
                let UseableItems = this.UseableItems
                let search_key = this.search_key
                if(!UseableItems){return null}
                if(!search_key){return UseableItems}
                let filtered_item = UseableItems.filter(item=>{
                    let search = this.MBS.actions.TEXT_UP(item.name)
                    if (!search) {
                        search=""
                    }
                    let index = search.indexOf(this.MBS.actions.TEXT_UP(search_key))
                    return index == -1?false:true
                })
                console.log(filtered_item,'filtered_item');
                return filtered_item

            },  
            CheckedItems(){
                const ItemsData = this.ItemsData
                if(!ItemsData){return null}
                let filtered = ItemsData.filter(item=>item.checked)
                return filtered
            },

            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            SELECTED_CARD(){
                let card = this.selected_card
                return card
            }, 
            ITEM_ORDER(){
                let order = this.item_order
                return order
            }, 
            
              
        },
        methods:{ 
            async loadMore(event) {
                let items = this.SearchedItems
                let isLoading = this.isLoading
                let currentPage = this.currentPage
                let totalPages = this.totalPages
                let pageSize = this.pageSize
                let pagination = this.pagination
                let paginatedItems = this.paginatedItems

                console.log(event,1111111111);
               
                // if (this.isLoading || this.currentPage >= this.totalPages) return;

                console.log(222222);
                this.isLoading = true;

                try { 
                    this.pagination = this.MBS.actions.pagination(items,currentPage,pageSize) 
                    this.paginatedItems = this.pagination?this.paginatedItems.concat(this.pagination.list) :null
                    this.currentPage++
                    console.log(pagination,'newData......');
                     
                } catch (error) {
                    console.error('Error loading more data:', error);
                }

                this.isLoading = false;
            },
            handleKeyDown(event) {
                // if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                //     event.preventDefault();
                // }
            },
            isEditing(rowIndex, columnIndex) {
            return this.editingCell && this.editingCell.row === rowIndex && this.editingCell.column === columnIndex;
            },
            startEditing(rowIndex, columnIndex) {
            this.editingCell = { row: rowIndex, column: columnIndex };
            this.$nextTick(() => this.$refs.editField.focus());
            },
            stopEditing(rowIndex, columnIndex) {
            this.editingCell = null;
            },
            selectRow(index) {
                if (this.lastSelectedRow !== -1 && this.$root.shiftKey) {
                    const start = Math.min(this.lastSelectedRow, index);
                    const end = Math.max(this.lastSelectedRow, index);
                    this.selectedRows = [];

                    for (let i = start; i <= end; i++) {
                    this.selectedRows.push(i);
                    }
                } else if (this.selectedRows.includes(index)) {
                    this.selectedRows.splice(this.selectedRows.indexOf(index), 1);
                } else {
                    this.selectedRows.push(index);
                } 
                this.lastSelectedRow = index;
            },


            startLongClick() {
                this.longClickTimer = setTimeout(() => {
                    this.longClickDetected = true;
                    this.item_multiple_select = !this.item_multiple_select;
                    console.log('Long click detected');
                }, 1000); 
            },
            endLongClick() {
                clearTimeout(this.longClickTimer);
                setTimeout(() => { 
                    this.longClickDetected = false;
                }, 10);
            },

            RESET_ALL_DATA(){ 
                try { 
                    Object.assign(this.$data, this.$options.data())  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RESET_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },

            INITIALIZE_SELLING_MODE(){
                try {
                    let CompanySettings = this.CompanySettings
                    if (!CompanySettings) {
                        
                    } else {
                        if (CompanySettings.both_selling_mode) {
                            this.selling_model = ""
                        }if (CompanySettings.only_retail_model) {
                            this.selling_model = "Retail Price"
                        }if (CompanySettings.only_wholesale_model) {
                            this.selling_model = "Wholesale Price"
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_SELLING_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    const sold_price = this.toNumber(item.sold_price)
                    const discount = this.toNumber(item.discount)
                    const quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_SELECT_ITEM(item,index){
                try {  
                    if (!this.longClickDetected) {
                        this.$emit("item_selected",item,index) 
                        this.ON_SELECT([item])
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT(items){
                try {  
                    this.RESET_ALL_DATA()
                    this.$emit("selected",items)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_SELECTED_ITEM(){
                try { 
                    let input = this.input
                    // this.current_selected_item=item
                    this.dialog_edit_selected_item = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            CONFIRM_REMOVE_ITEM(selected_item,index){
                try { 
                    this.dialog_edit_selected_item = false
                    this.$emit("confirm_remove_item",selected_item,index)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(data){
                try { 
                    this.$emit("remove_item",data)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CHECK_ITEM(item,index){
                try { 
                    // this.selected_items[index] = {
                    //     ...item,
                    //     checked: item.check? true :false
                    // }
                    console.log(item);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FOCUS_INPUT(field){
                this.focused_field = field 
            },
            ON_SEARCH(selling_model){
                try {    
                    if (!selling_model) { 
                        this.menu_selling_mode = true
                    }
                    console.log(this.menu_selling_mode,'menu_selling_mode....')
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            //---------------[MAIN FUNCTIONS]------------   
            ON_KEY_DOWN(key,time){
                try {
                    if (key=='Enter') {   

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1);

                            console.log(this.input,'input......');
                            console.log(value,'value......');
                        }
                        
                    }
                }
            }, 

        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            CompanySettings(value){
                if (value) {
                    this.INITIALIZE_SELLING_MODE() 
                }
            },
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)
            },
            search_item(value){ 
                if (value) {
                    // this.ADD_ITEM(value)  
                    console.log(value,'sea........')
                    this.$emit("searched_item_key",value)
                    this.$emit("input",{
                        selling_model:this.selling_model,
                        searched_item_key:value,
                    }) 
                    setTimeout(() => {
                        this.search_item=''
                    }, 50);
                }
            }, 
            selling_model(value){ 
                this.$emit("input",{
                    selling_model:value
                }) 
            }, 
        } 

    }
</script>
<style>
    .selected {
        background-color: #a8dadc;
    }
    .checked {
        background-color: #dadada;
    }
    
</style> 